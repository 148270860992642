/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'expose-loader?$!jquery';
import 'expose-loader?jQuery!jquery';

import "venobox/venobox/venobox.js";
import "bootstrap-multiselect/dist/js/bootstrap-multiselect";
import "bootstrap-sass/assets/javascripts/bootstrap";
import "bootstrap-datepicker/dist/js/bootstrap-datepicker";
import "jscroll/jquery.jscroll";
import "hc-offcanvas-nav/dist/hc-offcanvas-nav.js";
import "reading-time/build/readingtime.min";
import "@nathanvda/cocoon";

import Cookies from "js.cookie";
import $ from "jquery";
import Rails from 'rails-ujs';
import * as ActiveStorage from 'activestorage';
import { Calendar } from '@fullcalendar/core';
import deLocale from '@fullcalendar/core/locales/de';
import itLocale from '@fullcalendar/core/locales/it';
import frLocale from '@fullcalendar/core/locales/fr';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';

Rails.start();
ActiveStorage.start();

var smoothScrollTo;

$.when($.ready).then(function () {
    var $html = $('html');
    var cookieButton, error;

    $('[data-toggle="tooltip"]').tooltip();

    $.ajaxSetup({
        cache: false
    });
    cookieButton = $('.js-cookies-eu-ok');
    if (cookieButton.length > 0) {
        if (Cookies.get('cookie_eu_consented') === !void 0) {
            $('.js-cookies-eu').remove();
        } else {
            cookieButton.on('click', function () {
                Cookies.set('cookie_eu_consented', true, {
                    path: '/',
                    expires: 365
                });
                return $('.js-cookies-eu').remove();
            });
        }
    }
    error = $('.article-comments__form .form-group.has-error');
    if (error.length > 0) {
        smoothScrollTo(error);
    }

    let $calendar = $('#calendar')
    if ($calendar.length) {
        let defaultDate = new Date();
        if (Cookies.get('events_date')) {
            defaultDate = Cookies.get('events_date');
        }

        let eventSources = [$calendar.data('url')];
        if ($calendar.data('feeds-url')) {
            eventSources.push($calendar.data('feeds-url'))
        }
        if ($calendar.data('data-url')) {
            eventSources.push($calendar.data('data-url'))
        }

        deLocale['buttonText']['list'] = 'Liste';

        // Loading message element, with a spinner
        let loadingMessageEl = document.createElement('div');
        loadingMessageEl.className = 'calendar-loading-message';

        let loadingTextEl = document.createElement('span');
        loadingTextEl.textContent = 'Loading events...';
        let spinnerEl = document.createElement('div');
        spinnerEl.className = 'calendar-loading-spinner';

        loadingMessageEl.appendChild(loadingTextEl);
        loadingMessageEl.appendChild(spinnerEl);
        $calendar.after(loadingMessageEl); // Insert after the calendar

        // Custom 'no events' message
        let noEventsMessageEl = document.createElement('div');
        noEventsMessageEl.className = 'calendar-no-events-message';
        noEventsMessageEl.textContent = 'No events to display.';

        $calendar.after(noEventsMessageEl); // Insert after the calendar
        noEventsMessageEl.style.display = 'none'; // Initially hidden

        let calendar = new Calendar($calendar[0], {
            locales: [deLocale, itLocale, frLocale],
            locale: $html.attr('lang'),
            plugins: [ dayGridPlugin, listPlugin ],
            eventSources: eventSources,
            displayEventTime: false,
            defaultView: 'listMonth',
            defaultDate: defaultDate,
            header: {
                left: 'prev,next today',
                center: 'title',
                right: 'listMonth,dayGridMonth'
            },
            views: {
                dayGridMonth: {
                    columnHeaderFormat: {weekday: 'long'},
                    eventTimeFormat: {
                        hour: 'numeric',
                        minute: '2-digit',
                        meridiem: false
                    },
                    eventLimit: 2
                },
                list: {
                    listDayFormat: { weekday: 'long' },
                    listDayAltFormat: { day: 'numeric', month: 'long', year: 'numeric'}
                }
            },
            loading: function(isLoading) {
                if (isLoading) {
                    // Re-add loading message if it was removed
                    if (!document.querySelector('.calendar-loading-message')) {
                        $calendar.after(loadingMessageEl);
                    }
                    // Show loading message
                    loadingMessageEl.style.display = 'block';
                    noEventsMessageEl.style.display = 'none'; // Hide no events message while loading
                    // Hide the default "No events to display" message
                    $('.fc-no-events').hide();
                } else {
                    // Remove loading message, just hiding doesn't work
                    $('.calendar-loading-message').remove();
                    // Check if there are any events
                    if (calendar.getEvents().length === 0) {
                        noEventsMessageEl.style.display = 'block'; // Show 'no events' message
                    } else {
                        noEventsMessageEl.style.display = 'none'; // Hide no events message
                        // Hide the default "No events to display" message
                        $('.fc-no-events').hide();
                    }
                }
            },
            eventRender: function(event) {
                let small = '<small>' + event.event.extendedProps.organizer + '<br>' + event.event.extendedProps.location + '</small>';

                // if in list view, we adjust some settings
                if (event.view.type === "listMonth") {
                    let $a = $(event.el).find('a')
                    $a.text(event.event.extendedProps.title);
                    $a.after(small)
                } else if (event.view.type === "dayGridMonth") {
                    let $title = $(event.el).find('.fc-title')
                    $title.text(event.event.extendedProps.title);
                    $title.append(small)
                }

                event.el.style.height = '70px';
                $(event.el).attr('title', event.event.extendedProps.title).css('background-color', event.event.backgroundColor);
            },
            eventClick: function () {
                Cookies.set('events_date', calendar.getDate());
            }
        });

        calendar.render();
    }

    $html.removeClass('no-js');
    $('.venobox').venobox({
        numeratio: true
    });
    $('.articles-boxes').jscroll({
        loadingHtml: '<div class="articles-boxes__load-more"><i class="fa fa-refresh fa-spin"></i></div>',
        nextSelector: '.articles-boxes__load-more',
        autoTriggerUntil: 1
    });
    $('.l-main-navigation .dropdown').on('show.bs.dropdown', function () {
        return $html.addClass('noscroll');
    }).on('hide.bs.dropdown', function () {
        return $html.removeClass('noscroll');
    });

    $('#bookmark-this').on('click', function (e) {
        if (window.sidebar && window.sidebar.addPanel) { // Mozilla Firefox Bookmark
            window.sidebar.addPanel(document.title, window.location.href, '');
        } else if (window.external && ('AddFavorite' in window.external)) { // IE Favorite
            window.external.AddFavorite(location.href, document.title);
        } else if (window.opera && window.print) { // Opera Hotlist
            this.title = document.title;
            return true;
        } else { // webkit - safari/chrome
            alert('Press ' + (navigator.userAgent.toLowerCase().indexOf('mac') != -1 ? 'Command/Cmd' : 'CTRL') + ' + D to bookmark this page.');
        }
    });

    if ($('#js-eta').length) {
        $('[itemprop="articleBody"]').readingTime({
            lang: $html.attr('lang'),
            readingTimeTarget: '#js-eta',
            wordsPerMinute: 220,
            lessThanAMinuteString: '1 min'
        });
    }
    // Initialize TinyMCE on inserted blocks
    // Cocoon event does not work, so this is a workaround
    $('.js-text-block').on('click', function () {
        setTimeout(function () {
            return TinyMCERails.initialize();
        }, 100);
    });
});

smoothScrollTo = function (target) {
    return setTimeout(function () {
        return $('html,body').animate({
            scrollTop: target.offset().top
        }, 875, function () {
            target.attr('tabindex', '-1');
            target.focus();
            if (target.hasAttribute('id')) {
                return location.hash = target.attr('id');
            }
        });
    });
};
